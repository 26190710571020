import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from 'src/app/core/services/language.service';
import { HttpClient } from '@angular/common/http';
import { MailService } from '../../core/services/mail.service';
import './typewriter.js';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-solo',
  templateUrl: './solo.component.html',
  styleUrls: ['./solo.component.scss', './Home.css', './nicepage.css']
})

/**
 * Coming-soon component
 */
export class SoloComponent {

  constructor(
    public languageService: LanguageService,
    public _cookiesService: CookieService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private mailService: MailService
  ) { }
  error;
  submitted;
  success;
  loading;
  subscription: Subscription;
  skills = [
    {
      name: 'JS',
      done: true
    },
    {
      name: 'Gulp',
      done: true
    },
    {
      name: 'Angular',
      done: true
    },
    {
      name: 'Flutter',
      done: false
    },
  ];
  contactMeForm = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    message: ["Hi Solo,\n\nWe are excited to hear about your services.\n\nRegards,\n", Validators.required],
    // message: ["Hi Solo,\n\nI'll be waiting for your call.\n\nRegards,\n", Validators.required],
    // on success popup, suggest going to FAQs
  });

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openLargeModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  openContactModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  scrollToContact() {
    document.getElementById("name").focus();
    document.getElementById("social").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  contactMe() {
    if (this.contactMeForm.invalid) {
      this.submitted = true;
    } else {
      this.loading = true;
      let data = this.contactMeForm.value;
      this.subscription = this.mailService.contactMe(data).subscribe(
        data => {
          this.loading = false;
          this.success = true;
          let res: any = data;
        },
        err => {
          console.log(err);
          this.loading = false;
        }
      );
    }
  }
}
