<div class="u-body u-xl-mode">
    <!-- <link rel="stylesheet" href="nicepage.css" media="screen">
    <link rel="stylesheet" href="Home.css" media="screen"> -->

    <meta name="generator" content="Nicepage 4.16.0, nicepage.com">
    <link id="u-theme-google-font" rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i|Open+Sans:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i">
    <link id="u-page-google-font" rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700|Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i">

    <script type="application/ld+json">{
		"@context": "http://schema.org",
		"@type": "Organization",
		"name": "",
		"logo": "./images/SOLODEVLOGO.png"
}</script>

    <header class="u-clearfix u-header u-header" id="sec-190b">
        <div class="u-clearfix u-sheet u-sheet-1">
            <a href="/solo" class="u-image u-logo u-image-1" data-image-width="1022" data-image-height="149" aria-label="navigate to homepage">
                <img src="/assets/images/nicepage/SOLODEVLOGO.png" class="solo-logo u-logo-image u-logo-image-1" alt="solo technologies logo">
                <img src="/assets/images/nicepage/slogen-2.png" class="slogen u-logo-image u-logo-image-1" alt="solo technologies slogen (evrey pixel matters)">
            </a>
        </div>
    </header>
    <section class="u-align-right u-clearfix u-image u-shading u-section-1" id="carousel_c813">
        <div class="u-clearfix u-sheet u-sheet-1">
            <h1 class="u-align-left u-custom-font u-font-oswald u-text u-text-body-alt-color u-text-1">
                Super-Awesome Applications
                <!-- Web Development -->
                <!-- Digitl transformation starts here... -->
                <!-- <h2 href="" class="typewrite" data-period="2000"
                    data-type='[ "Web App", "Mobile App" ]'>
                    <span class="wrap"></span>
        </h2> -->
            </h1>
            <div class="u-list u-list-1">
                <div class="u-repeater u-repeater-1">
                    <div
                        class="u-align-center u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-video-cover u-white u-list-item-1">
                        <div class="u-container-layout u-similar-container u-valign-middle u-container-layout-1"><span
                                class="u-grey-10 u-icon u-icon-circle u-text-palette-1-base u-icon-1"><svg
                                    class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 512 512"
                                    style="">
                                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-d293"></use>
                                </svg><svg class="u-svg-content" viewBox="0 0 512 512" x="0px" y="0px" id="svg-d293"
                                    style="enable-background:new 0 0 512 512;">
                                    <g>
                                        <g>
                                            <path
                                                d="M386.607,304.394L367.213,285l19.393-19.393c5.859-5.858,5.859-15.356,0-21.214c-5.857-5.858-15.355-5.858-21.213,0    L346,263.787l-19.394-19.394c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213L324.787,285l-19.393,19.393    c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0L346,306.213l19.394,19.394    c5.857,5.857,15.355,5.858,21.213,0C392.465,319.749,392.465,310.252,386.607,304.394z">
                                            </path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path
                                                d="M206.607,184.394L187.213,165l19.393-19.393c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0    L166,143.787l-19.394-19.394c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213L144.787,165l-19.393,19.393    c-5.859,5.858-5.859,15.356,0,21.214c5.857,5.857,15.355,5.858,21.213,0L166,186.213l19.394,19.394    c5.857,5.857,15.355,5.858,21.213,0C212.465,199.749,212.465,190.252,206.607,184.394z">
                                            </path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path
                                                d="M416.606,124.394l-30-30c-0.029-0.029-0.06-0.052-0.089-0.081c-5.916-5.822-15.373-5.668-21.124,0.081l-30,30    c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.858,15.355,5.858,21.213,0l4.394-4.394V180H256c-8.284,0-15,6.716-15,15v75h-32.58    c-6.192-17.459-22.865-30-42.42-30c-24.813,0-45,20.187-45,45s20.187,45,45,45c19.555,0,36.228-12.541,42.42-30H256    c8.284,0,15-6.716,15-15v-75h105c8.284,0,15-6.716,15-15v-53.787l4.394,4.394C398.322,148.535,402.161,150,406,150    s7.678-1.465,10.606-4.393C422.464,139.749,422.464,130.252,416.606,124.394z M166,300c-8.271,0-15-6.729-15-15s6.729-15,15-15    s15,6.729,15,15S174.271,300,166,300z">
                                            </path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path
                                                d="M467,0H45C20.187,0,0,20.187,0,45c0,19.555,12.541,36.228,30,42.42V345c0,24.813,20.187,45,45,45h166v34.58    c-17.459,6.192-30,22.865-30,42.42c0,24.813,20.187,45,45,45s45-20.187,45-45c0-19.555-12.541-36.228-30-42.42V390h166    c24.813,0,45-20.187,45-45V87.42c17.459-6.192,30-22.865,30-42.42C512,20.187,491.813,0,467,0z M256,482c-8.271,0-15-6.729-15-15    s6.729-15,15-15s15,6.729,15,15S264.271,482,256,482z M452,345c0,8.271-6.729,15-15,15H75c-8.271,0-15-6.729-15-15V90h392V345z     M467,60H45c-8.271,0-15-6.729-15-15s6.729-15,15-15h422c8.271,0,15,6.729,15,15S475.271,60,467,60z">
                                            </path>
                                        </g>
                                    </g>
                                </svg></span>
                            <h4 class="u-text u-text-3">
                                <!-- copywriting -->
                                strategy
                            </h4>
                        </div>
                    </div>
                    <div
                        class="u-align-center u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-video-cover u-white u-list-item-2">
                        <div class="u-container-layout u-similar-container u-valign-middle u-container-layout-1"><span
                                class="u-grey-10 u-icon u-icon-circle u-text-palette-1-base u-icon-1"><svg
                                    class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 512 512"
                                    style="">
                                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-ddc0"></use>
                                </svg><svg class="u-svg-content" viewBox="0 0 512 512" x="0px" y="0px" id="svg-ddc0"
                                    style="enable-background:new 0 0 512 512;">
                                    <g>
                                        <g>
                                            <path
                                                d="M497,0h-60c-8.284,0-15,6.716-15,15v15H298.42C292.228,12.542,275.555,0,256,0s-36.228,12.542-42.42,30H90V15    c0-8.284-6.716-15-15-15H15C6.716,0,0,6.716,0,15v60c0,8.284,6.716,15,15,15h60c8.284,0,15-6.716,15-15V59.8h62.377    c-54.116,34.274-87.012,92.175-90.969,153.642C43.738,219.518,31,236.294,31,256c0,24.813,20.187,45,45,45s45-20.187,45-45    c0-19.38-12.315-35.938-29.53-42.26c5.326-70.647,56.198-131.494,124.226-148.758C223.07,79.794,238.361,90,256,90    c17.639,0,32.93-10.206,40.304-25.017c68.029,17.264,118.9,78.111,124.226,148.758C403.315,220.062,391,236.62,391,256    c0,24.813,20.187,45,45,45s45-20.187,45-45c0-19.706-12.738-36.482-30.408-42.558C446.627,151.852,413.633,94.207,359.623,60H422    v15c0,8.284,6.716,15,15,15h60c8.284,0,15-6.716,15-15V15C512,6.716,505.284,0,497,0z M60,60H30V30h30V60z M76,271    c-8.271,0-15-6.729-15-15s6.729-15,15-15s15,6.729,15,15S84.271,271,76,271z M256,60c-8.271,0-15-6.729-15-15s6.729-15,15-15    s15,6.729,15,15S264.271,60,256,60z M436,241c8.271,0,15,6.729,15,15s-6.729,15-15,15s-15-6.729-15-15S427.729,241,436,241z     M482,60h-30V30h30V60z">
                                            </path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path
                                                d="M388.48,307.679l-120-179.999c-0.004-0.006-0.009-0.012-0.013-0.019c-0.043-0.064-0.09-0.125-0.134-0.188    c-0.206-0.299-0.423-0.587-0.649-0.868c-6.321-7.854-18.357-7.324-24.017,0.868c-0.044,0.063-0.091,0.124-0.134,0.188    c-0.004,0.007-0.009,0.012-0.013,0.019l-120,180c-3.967,5.949-3.182,13.871,1.874,18.927    c29.927,29.927,48.975,69.047,54.164,110.901c-2.329,1.883-4.578,3.899-6.71,6.081C158.759,458.001,151,476.97,151,497    c0,8.284,6.716,15,15,15h180c8.284,0,15-6.716,15-15c0-20.03-7.759-38.999-21.847-53.412c-2.133-2.182-4.381-4.198-6.711-6.081    c5.188-41.856,24.236-80.976,54.163-110.9C391.662,321.551,392.447,313.628,388.48,307.679z M256,301c8.271,0,15,6.729,15,15    s-6.729,15-15,15s-15-6.729-15-15S247.729,301,256,301z M183.558,482c6.241-17.833,23.159-31,42.442-31h60    c19.283,0,36.201,13.167,42.442,31H183.558z M304.245,423.283C298.345,421.777,292.229,421,286,421h-60    c-6.229,0-12.345,0.777-18.245,2.284c-7.02-40.429-25.251-78.221-52.65-108.899L241,185.542v88.039    c-17.459,6.192-30,22.865-30,42.42c0,24.813,20.187,45,45,45s45-20.187,45-45c0-19.555-12.541-36.228-30-42.42v-88.039    l85.896,128.843C329.496,345.06,311.264,382.853,304.245,423.283z">
                                            </path>
                                        </g>
                                    </g>
                                </svg></span>
                            <h4 class="u-text u-text-3">
                                <!-- design -->
                                ux Design
                            </h4>
                        </div>
                    </div>
                    <div
                        class="u-align-center u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-video-cover u-white u-list-item-3">
                        <div class="u-container-layout u-similar-container u-valign-middle u-container-layout-1"><span
                                class="u-grey-10 u-icon u-icon-circle u-text-palette-1-base u-icon-1"><svg
                                    class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 512 512"
                                    style="">
                                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-ce99"></use>
                                </svg><svg class="u-svg-content" viewBox="0 0 512 512" x="0px" y="0px" id="svg-ce99"
                                    style="enable-background:new 0 0 512 512;">
                                    <g>
                                        <g>
                                            <path
                                                d="M0,15.845v420.259h164.332v30.049h-30.036v30.001h243.407v-30.001h-30.036v-30.049H512V15.845H0z M317.665,466.153    H194.334v-30.049h123.332V466.153z M481.999,406.103H30.001v-60.075h451.997V406.103z M481.999,316.027H30.001V45.847h451.997    V316.027z">
                                            </path>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <polygon
                                                points="203.943,126.712 182.729,105.498 107.29,180.937 182.729,256.375 203.943,235.161 149.718,180.937   ">
                                            </polygon>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <polygon
                                                points="329.271,105.498 308.057,126.712 362.282,180.937 308.057,235.161 329.271,256.375 404.709,180.937   ">
                                            </polygon>
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <rect x="166.742" y="165.947"
                                                transform="matrix(0.1951 -0.9808 0.9808 0.1951 28.5815 396.7237)"
                                                width="178.511" height="30.002"></rect>
                                        </g>
                                    </g>
                                </svg></span>
                            <h4 class="u-text u-text-3">development</h4>
                        </div>
                    </div>
                    <div
                        class="u-align-center u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-video-cover u-white u-list-item-4">
                        <div class="u-container-layout u-similar-container u-valign-middle u-container-layout-1"><span
                                class="u-grey-10 u-icon u-icon-circle u-text-palette-1-base u-icon-1"><svg
                                    class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 24 24"
                                    style="">
                                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-4417"></use>
                                </svg><svg class="u-svg-content" viewBox="0 0 24 24" id="svg-4417">
                                    <path
                                        d="m22.25 20h-20.5c-.965 0-1.75-.785-1.75-1.75v-12.5c0-.965.785-1.75 1.75-1.75h1.68c.414 0 .75.336.75.75s-.336.75-.75.75h-1.68c-.136 0-.25.114-.25.25v12.5c0 .136.114.25.25.25h20.5c.136 0 .25-.114.25-.25v-12.5c0-.136-.114-.25-.25-.25h-1.68c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.68c.965 0 1.75.785 1.75 1.75v12.5c0 .965-.785 1.75-1.75 1.75z">
                                    </path>
                                    <path
                                        d="m16.25 24h-8.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h8.5c.414 0 .75.336.75.75s-.336.75-.75.75z">
                                    </path>
                                    <path
                                        d="m12 24c-.414 0-.75-.336-.75-.75v-4c0-.414.336-.75.75-.75s.75.336.75.75v4c0 .414-.336.75-.75.75z">
                                    </path>
                                    <path
                                        d="m14 4.5h-4c-.414 0-.75-.336-.75-.75 0-1.517 1.233-2.75 2.75-2.75s2.75 1.233 2.75 2.75c0 .414-.336.75-.75.75zm-3-1.5h2c-.229-.304-.591-.5-1-.5s-.771.196-1 .5z">
                                    </path>
                                    <path
                                        d="m13.601 3.3c-.069 0-.139-.01-.208-.029-.398-.115-.628-.53-.513-.929.397-1.379 1.68-2.342 3.12-2.342.414 0 .75.336.75.75s-.336.75-.75.75c-.775 0-1.466.518-1.679 1.258-.095.328-.395.542-.72.542z">
                                    </path>
                                    <path
                                        d="m10.399 3.3c-.326 0-.625-.214-.72-.542-.213-.74-.904-1.258-1.679-1.258-.414 0-.75-.336-.75-.75s.336-.75.75-.75c1.44 0 2.723.963 3.121 2.342.115.398-.115.813-.513.929-.07.019-.14.029-.209.029z">
                                    </path>
                                    <path
                                        d="m14.75 7c-.336 0-.642-.228-.727-.568-.101-.401.144-.809.545-.909l1.666-.416.846-1.691c.186-.371.636-.521 1.006-.336.371.186.521.636.335 1.006l-1 2c-.098.196-.276.34-.489.393l-2 .5c-.061.014-.122.021-.182.021z">
                                    </path>
                                    <path
                                        d="m17.75 12c-.275 0-.54-.151-.671-.415l-.886-1.771-1.771-.858c-.373-.181-.528-.629-.348-1.002s.629-.527 1.002-.348l2 .97c.149.072.27.191.344.34l1 2c.185.37.035.82-.335 1.006-.107.053-.222.078-.335.078z">
                                    </path>
                                    <path
                                        d="m9.25 7c-.06 0-.121-.007-.182-.022l-2-.5c-.213-.053-.391-.197-.489-.393l-1-2c-.185-.37-.035-.82.335-1.006.372-.185.821-.035 1.006.336l.846 1.691 1.666.416c.402.101.646.508.545.909-.084.341-.391.569-.727.569z">
                                    </path>
                                    <path
                                        d="m6.25 12c-.113 0-.227-.025-.335-.079-.371-.186-.521-.636-.335-1.006l1-2c.074-.148.195-.268.344-.34l2-.97c.372-.178.821-.025 1.001.348.181.373.025.821-.348 1.002l-1.77.858-.886 1.771c-.131.265-.397.416-.671.416z">
                                    </path>
                                    <path
                                        d="m12 13c-1.93 0-3.5-1.57-3.5-3.5v-5.75c0-.414.336-.75.75-.75h5.5c.414 0 .75.336.75.75v5.75c0 1.93-1.57 3.5-3.5 3.5zm-2-8.5v5c0 1.103.897 2 2 2s2-.897 2-2v-5z">
                                    </path>
                                    <path
                                        d="m12 13c-.414 0-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75s.75.336.75.75v8.5c0 .414-.336.75-.75.75z">
                                    </path>
                                </svg></span>
                            <h4 class="u-text u-text-3">
                                <!-- 24/7 support -->
                                testing
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section
        class="u-align-center-lg u-align-center-md u-align-center-xl u-align-left-sm u-align-left-xs u-clearfix u-grey-5 u-section-2"
        id="carousel_6d90">
        <div class="u-clearfix u-sheet u-valign-middle u-sheet-1">
            <h2 class="u-text u-text-1">Your <b>App</b> is Our <b>Map</b></h2>
            <p class="u-text u-text-2">Mixing your ideas with Solo strategic planning will take us on result-driven journey</p>
            <div class="u-expanded-width u-list u-list-1">
                <div class="u-repeater u-repeater-1">
                    <div
                        class="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-1">
                        <div class="u-container-layout u-similar-container u-container-layout-1"><span
                                class="u-file-icon u-icon u-icon-circle u-palette-1-base u-text-white u-icon-1">
                                <img src="/assets/images/nicepage/1828743-b773816f.png" alt="checkmark shape"></span>
                            <h5 class="u-custom-font u-text u-text-font u-text-3">
                                <!-- Strategy -->
                                Amazing Design
                            </h5>
                            <p class="u-text u-text-4">​​An App that is easy to navigate and understood by everyone.</p>
                        </div>
                    </div>
                    <div
                        class="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-2">
                        <div class="u-container-layout u-similar-container u-container-layout-2"><span
                                class="u-file-icon u-icon u-icon-circle u-palette-1-base u-text-white u-icon-1">
                                <img src="/assets/images/nicepage/1828743-b773816f.png" alt="checkmark shape"></span>
                            <h5 class="u-custom-font u-text u-text-font u-text-3">
                                <!-- UX DESIGN -->
                                Looks Cool Everywhere
                            </h5>
                            <p class="u-text u-text-4">Sometimes.. having a website is not enough! we will make sure that your application will look cool on mobile apps, POS, smart watches and more.</p>
                        </div>
                    </div>
                    <div
                        class="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-3">
                        <div class="u-container-layout u-similar-container u-container-layout-3"><span
                                class="u-file-icon u-icon u-icon-circle u-palette-1-base u-text-white u-icon-1">
                                <img src="/assets/images/nicepage/1828743-b773816f.png" alt="checkmark shape"></span>
                            <h5 class="u-custom-font u-text u-text-font u-text-3">Advanced SEO</h5>
                            <p class="u-text u-text-4">New business need to be integrated with google crawlers, have visitors analysis dashboard and Campaign management tools.</p>
                        </div>
                    </div>
                    <div
                        class="u-align-left u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-4">
                        <div class="u-container-layout u-similar-container u-container-layout-4"><span
                                class="u-file-icon u-icon u-icon-circle u-palette-1-base u-text-white u-icon-1">
                                <img src="/assets/images/nicepage/1828743-b773816f.png" alt="checkmark shape"></span>
                            <h5 class="u-custom-font u-text u-text-font u-text-3">
                                <!-- Testing -->
                                24/7 Support                            </h5>
                            <p class="u-text u-text-4">You can choose from 3 Months, 6 Months and Yearly support Plans.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="u-clearfix u-section-4" id="carousel_f063">
        <div class="u-clearfix u-sheet u-valign-middle u-sheet-1">
            <div class="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
                <div class="u-layout">
                    <div class="u-layout-row">
                        <div
                            class="u-align-center u-container-style u-layout-cell u-size-20-lg u-size-20-xl u-size-28-md u-size-28-sm u-size-28-xs u-layout-cell-1">
                            <div
                                class="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-sm u-valign-middle-xl u-container-layout-1">
                                <div
                                    class="u-expanded-width-xs u-palette-1-base u-preserve-proportions u-shape u-shape-circle u-shape-1">
                                    <div class="u-preserve-proportions-child" style="padding-top: 100%;"></div>
                                </div>
                                <img class="u-image u-image-contain u-image-round u-radius-30 u-image-1"
                                    src="/assets/images/nicepage/phone-2-min.webp" alt="mobile phone" data-image-width="406"
                                    data-image-height="718">
                            </div>
                        </div>
                        <div
                            class="u-align-left u-container-style u-layout-cell u-size-32-md u-size-32-sm u-size-32-xs u-size-40-lg u-size-40-xl u-layout-cell-2">
                            <div class="u-container-layout u-valign-middle u-container-layout-2">
                                <h5 class="u-custom-font u-text u-text-font u-text-1">about us</h5>
                                <h2 class="u-text u-text-2">Web Design Company in Dubai</h2>
                                <p class="u-text u-text-3 line-27">Solo is a result-driven website design company and digital agency based in Dubai. Our skilled in-house team provides website development, Magento e-commerce, mobile applications, social media & SEO services. We have extensive experience and a track record that ensures your website connects meaningfully with your customers. Our approach looks beyond immediate business needs to frame your website design & digital marketing project as an ongoing extension of your Brand's core promise.</p>
                                <div class="about-table">
                                    <div class="about-table__box">
                                        <div class="about-table__number">12+</div>
                                        <div class="about-table__title">In-House Team</div>
                                    </div>
                                    <div class="about-table__box">
                                        <div class="about-table__number">7+</div>
                                        <div class="about-table__title">Years of Experiance</div>
                                    </div>
                                </div>
                                <!-- <ul class="u-custom-list u-text u-text-default u-text-4">
                                    <li>
                                        <div class="u-list-icon u-text-palette-2-base">
                                            <div xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                                xml:space="preserve" class="u-svg-content">►</div>
                                        </div> Windows, Mac, Big and Huge Screens
                                    </li>
                                    <li>
                                        <div class="u-list-icon u-text-palette-2-base">
                                            <div xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                                xml:space="preserve" class="u-svg-content">►</div>
                                        </div>Arabic App? Your Right to Left Design Is Ready !
                                    </li>
                                </ul>
                                <a href="https://nicepage.studio"
                                    class="u-active-black u-btn u-btn-round u-button-style u-hover-black u-palette-1-base u-radius-50 u-btn-1">read
                                    more</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="u-clearfix u-section-3" id="carousel_d279">
        <div
            class="u-clearfix u-sheet u-valign-middle-md u-valign-middle-sm u-valign-middle-xl u-valign-middle-xs u-sheet-1">
            <div
                class="u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-palette-1-base u-radius-20 u-shape u-shape-round u-shape-1">
            </div>
            <img class="u-image u-image-round u-radius-20 u-image-1" src="/assets/images/nicepage/vvv.webp" alt="man holding laptop"
                data-image-width="1139" data-image-height="1080">
            <div class="u-container-style u-group u-group-1">
                <div class="u-container-layout u-valign-top u-container-layout-1">
                    <h3 class="u-text u-text-body-alt-color u-text-default u-text-1"> Ready to go with you..
                        <br>
                    </h3>
                    <p class="u-text u-text-body-alt-color u-text-2">Our team is fully ready to be your guide in this journey, right from the start until your revenues are high in the sky, and beyond..
                        <br><br><br><br>
                    </p>
                    <a (click)="openContactModal(needCode)" aria-label="open contact us form"
                        class="u-active-white u-border-2 u-border-white u-btn u-btn-round u-button-style u-hover-white u-none u-radius-50 u-btn-2">Try Once, Stay Forever</a>
                </div>
            </div>
        </div>
    </section>
    <section class="u-align-center u-clearfix u-block-7098-1" custom-posts-hash="T" data-post-id="1096038250" data-section-properties="{&quot;grid-spacing&quot;:&quot;10&quot;,&quot;spacingAdditional&quot;:&quot;none&quot;,&quot;stretch&quot;:true}" data-id="7098" data-posts-content="[{'images':[],'maps':[],'videos':[],'icons':[],'textWidth':1140,'textHeight':786,'id':1,'headingProp':'h2','textProp':'text'}]" style="" data-style="gallery-2" id="sec-835f" data-source="Sketch">
        <div class="">
            <div class="gallery d-flex">
                <div class="d-flex flex-wrap">
                    <div class="col-6 col-lg-4 p-0">
                        <img class="img-fluid" src="/assets/images/projects/gallery/top-steering.webp" alt="Top Steering - Client name">
                    </div>
                    <div class="col-6 col-lg-4 p-0">
                        <img class="img-fluid" src="/assets/images/projects/gallery/jarir.webp" alt="Jarir - Client name">
                    </div>
                    <div class="col-6 col-lg-4 p-0 d-block d-lg-none">
                        <img class="img-fluid" src="/assets/images/projects/gallery/stal-2.webp" alt="Stal Arabia - Client name">
                    </div>
                    <div class="col-6 col-lg-4 p-0">
                        <img class="img-fluid" src="/assets/images/projects/gallery/wevisors.webp" alt="Wevisors - Client name">
                    </div>
                    <div class="col-6 col-lg-4 p-0 d-none d-lg-block">
                        <img class="img-fluid" src="/assets/images/projects/gallery/stal-2.webp" alt="Stal Arabia - Client name">
                    </div>
                    <div class="col-6 col-lg-4 p-0">
                        <img class="img-fluid" src="/assets/images/projects/gallery/motorzat-3.webp" alt="Motorzat - Client name">
                    </div>
                    <div class="col-6 col-lg-4 p-0">
                        <img class="img-fluid" src="/assets/images/projects/gallery/reach-2.webp" alt="Reach Immigration - Client name">
                    </div>
                </div>
            </div>
        </div>      
    </section>
    <!-- <section class="u-clearfix u-grey-5 u-section-5" id="carousel_13cc">
        <div class="u-clearfix u-sheet u-valign-middle u-sheet-1">
            <div class="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
                <div class="u-layout">
                    <div class="u-layout-row">
                        <div
                            class="u-align-center-sm u-align-center-xs u-align-left-md u-container-style u-layout-cell u-size-14-md u-size-14-sm u-size-14-xs u-size-8-lg u-size-8-xl u-white u-layout-cell-1">
                            <div class="u-container-layout u-valign-middle u-container-layout-1"><span
                                    class="u-icon u-icon-circle u-palette-1-base u-text-white u-icon-1"><svg
                                        class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 60 60"
                                        style="">
                                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-97b5"></use>
                                    </svg><svg class="u-svg-content" viewBox="0 0 60 60" x="0px" y="0px" id="svg-97b5"
                                        style="enable-background:new 0 0 60 60;">
                                        <g>
                                            <path
                                                d="M42.595,0H17.405C14.977,0,13,1.977,13,4.405v51.189C13,58.023,14.977,60,17.405,60h25.189C45.023,60,47,58.023,47,55.595
		V4.405C47,1.977,45.023,0,42.595,0z M15,8h30v38H15V8z M17.405,2h25.189C43.921,2,45,3.079,45,4.405V6H15V4.405
		C15,3.079,16.079,2,17.405,2z M42.595,58H17.405C16.079,58,15,56.921,15,55.595V48h30v7.595C45,56.921,43.921,58,42.595,58z">
                                            </path>
                                            <path d="M30,49c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4S32.206,49,30,49z M30,55c-1.103,0-2-0.897-2-2s0.897-2,2-2
		s2,0.897,2,2S31.103,55,30,55z"></path>
                                            <path
                                                d="M26,5h4c0.553,0,1-0.447,1-1s-0.447-1-1-1h-4c-0.553,0-1,0.447-1,1S25.447,5,26,5z">
                                            </path>
                                            <path
                                                d="M33,5h1c0.553,0,1-0.447,1-1s-0.447-1-1-1h-1c-0.553,0-1,0.447-1,1S32.447,5,33,5z">
                                            </path>
                                            <path d="M56.612,4.569c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414c3.736,3.736,3.736,9.815,0,13.552
		c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293
		C61.128,16.434,61.128,9.085,56.612,4.569z"></path>
                                            <path d="M52.401,6.845c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414c1.237,1.237,1.918,2.885,1.918,4.639
		s-0.681,3.401-1.918,4.638c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293
		c1.615-1.614,2.504-3.764,2.504-6.052S54.017,8.459,52.401,6.845z"></path>
                                            <path d="M4.802,5.983c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0c-4.516,4.516-4.516,11.864,0,16.38
		c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414
		C1.065,15.799,1.065,9.72,4.802,5.983z"></path>
                                            <path d="M9.013,6.569c-0.391-0.391-1.023-0.391-1.414,0c-1.615,1.614-2.504,3.764-2.504,6.052s0.889,4.438,2.504,6.053
		c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414
		c-1.237-1.237-1.918-2.885-1.918-4.639S7.775,9.22,9.013,7.983C9.403,7.593,9.403,6.96,9.013,6.569z"></path>
                                        </g>
                                    </svg></span>
                            </div>
                        </div>
                        <div
                            class="u-align-center-sm u-align-center-xs u-container-style u-layout-cell u-size-35-lg u-size-39-xl u-size-46-md u-size-46-sm u-size-46-xs u-white u-layout-cell-2">
                            <div class="u-container-layout u-valign-middle u-container-layout-2">
                                <h2 class="u-text u-text-default u-text-1"> Let's Get Started</h2>
                                <h6 class="u-custom-font u-font-montserrat u-text u-text-2"> Have a project or
                                    partnership in mind?<br>Contact us today for a free consultation.
                                </h6>
                            </div>
                        </div>
                        <div
                            class="u-align-center-lg u-align-center-sm u-align-center-xl u-align-center-xs u-align-left-md u-container-style u-layout-cell u-size-13-xl u-size-17-lg u-size-60-md u-size-60-sm u-size-60-xs u-white u-layout-cell-3">
                            <div class="u-container-layout u-valign-middle u-container-layout-3">
                                <a (click)="openContactModal(needCode)"
                                    class="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-1">Contact
                                    Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <section class="d-none d-md-block u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xs u-clearfix u-section-6"
        id="carousel_0edf">
        <div class="u-clearfix u-sheet u-valign-middle u-sheet-1">
            <img class="u-image u-image-round u-radius-20 u-image-1" src="/assets/images/nicepage/ret-min.webp" alt="hav an idea?"
                data-image-width="1600" data-image-height="1067">
            <div class="u-shape u-shape-svg u-text-palette-1-base u-shape-1">
                <svg class="u-svg-link" preserveAspectRatio="none" viewBox="0 0 160 160" style="">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-db78"></use>
                </svg>
                <svg class="u-svg-content" viewBox="0 0 160 160" x="0px" y="0px" id="svg-db78">
                    <path d="M114.3,152.3l38-38C144.4,130.9,130.9,144.4,114.3,152.3z M117.1,9.1l-108,108c0.8,1.6,1.7,3.2,2.7,4.8l110-110
	C120.3,10.9,118.7,10,117.1,9.1z M97.5,2L2,97.5c0.4,2,1,4,1.5,5.9l99.9-99.9C101.5,2.9,99.5,2.4,97.5,2z M80,160c2,0,4-0.1,5.9-0.2
	l73.9-73.9c0.1-2,0.2-3.9,0.2-5.9c0-0.6,0-1.2,0-1.9L78.1,160C78.8,160,79.4,160,80,160z M34.9,146.1c1.5,1,3,2,4.6,2.9L149,39.5
	c-0.9-1.6-1.9-3.1-2.9-4.6L34.9,146.1z M132.7,19.8L19.8,132.7c1.2,1.3,2.3,2.6,3.6,3.9L136.6,23.4C135.3,22.2,134,21,132.7,19.8z
	 M59.6,157.4l97.8-97.8c-0.5-1.9-1.1-3.8-1.7-5.7L53.9,155.6C55.8,156.3,57.7,156.9,59.6,157.4z M7.6,45.9L45.9,7.6
	C29.1,15.5,15.5,29.1,7.6,45.9z M80,0c-2.6,0-5.1,0.1-7.6,0.4l-72,72C0.1,74.9,0,77.4,0,80c0,0.1,0,0.2,0,0.2L80.2,0
	C80.2,0,80.1,0,80,0z"></path>
                </svg>
            </div>
            <div class="u-palette-1-base u-shape u-shape-circle u-shape-2"></div>
            <div class="u-align-left u-container-style u-grey-5 u-group u-radius-20 u-shape-round u-group-1">
                <div class="u-container-layout u-valign-middle u-container-layout-1">
                    <h3 class="u-text u-text-1">Have an Idea ?</h3>
                    <p class="u-text u-text-2">Take yout first step now and let's put it on work.</p>
                    <a (click)="scrollToContact()" aria-label="scroll to contact us form"
                        class="u-active-black u-border-none u-btn u-btn-round u-button-style u-hover-black u-palette-1-base u-radius-50 u-btn-2">Let's Get Started</a>
                </div>
            </div>
            <img class="u-image u-image-round u-radius-20 u-image-2" src="/assets/images/nicepage/fdffffff-min.webp" alt="girl"
                data-image-width="1100" data-image-height="999">
        </div>
    </section>
    <!-- <section class="u-align-center u-clearfix u-grey-5 u-section-7" id="carousel_1d42">
        <div class="u-clearfix u-sheet u-valign-middle u-sheet-1">
            <h2 class="u-text u-text-default u-text-1"> How We Help People</h2>
            <p class="u-text u-text-2"> With serious savings, a seamless online application, and unique community
                benefits, our members have a lot to say about our loans!</p>
            <div class="u-expanded-width u-list u-list-1">
                <div class="u-repeater u-repeater-1">
                    <div class="u-container-style u-list-item u-repeater-item">
                        <div
                            class="u-container-layout u-similar-container u-valign-top-sm u-valign-top-xs u-container-layout-1">
                            <div
                                class="u-border-2 u-border-palette-1-base u-container-style u-expanded-width u-group u-palette-1-base u-radius-20 u-shape-round u-group-1">
                                <div class="u-container-layout u-valign-top u-container-layout-2"><span
                                        class="u-file-icon u-icon u-text-white u-icon-1"><img
                                            src="/assets/images/nicepage/2087858-7c19edfa.png" alt=""></span>
                                    <p class="u-text u-text-3"> Article evident arrived express highest men did boy.
                                        Mistress sensible entirely am so. Quick can manor smart money hopes worth too.
                                        Comfort produce husband boy her had hearing.</p>
                                </div>
                            </div>
                            <div class="u-border-6 u-border-palette-1-base u-image u-image-circle u-image-1" alt=""
                                data-image-width="1000" data-image-height="876"></div>
                            <h6 class="u-custom-font u-font-montserrat u-text u-text-4">May Smith</h6>
                        </div>
                    </div>
                    <div class="u-container-style u-list-item u-repeater-item">
                        <div
                            class="u-container-layout u-similar-container u-valign-top-sm u-valign-top-xs u-container-layout-3">
                            <div
                                class="u-border-2 u-border-palette-1-base u-container-style u-expanded-width u-group u-palette-1-base u-radius-20 u-shape-round u-group-2">
                                <div class="u-container-layout u-valign-top u-container-layout-4"><span
                                        class="u-file-icon u-icon u-text-white u-icon-2"><img
                                            src="/assets/images/nicepage/2087858-7c19edfa.png" alt=""></span>
                                    <p class="u-text u-text-default u-text-5"> Article evident arrived express highest
                                        men did boy. Mistress sensible entirely am so. Quick can manor smart money hopes
                                        worth too. Comfort produce husband boy her had hearing.</p>
                                </div>
                            </div>
                            <div class="u-border-6 u-border-palette-1-base u-image u-image-circle u-image-2" alt=""
                                data-image-width="720" data-image-height="1080"></div>
                            <h6 class="u-custom-font u-font-montserrat u-text u-text-6">Bob Perry</h6>
                        </div>
                    </div>
                    <div class="u-container-style u-list-item u-repeater-item">
                        <div
                            class="u-container-layout u-similar-container u-valign-top-sm u-valign-top-xs u-container-layout-5">
                            <div
                                class="u-border-2 u-border-palette-1-base u-container-style u-expanded-width u-group u-palette-1-base u-radius-20 u-shape-round u-group-3">
                                <div class="u-container-layout u-valign-top u-container-layout-6"><span
                                        class="u-file-icon u-icon u-text-white u-icon-3"><img
                                            src="/assets/images/nicepage/2087858-7c19edfa.png" alt=""></span>
                                    <p class="u-text u-text-default u-text-7"> Article evident arrived express highest
                                        men did boy. Mistress sensible entirely am so. Quick can manor smart money hopes
                                        worth too. Comfort produce husband boy her had hearing.</p>
                                </div>
                            </div>
                            <div class="u-border-6 u-border-palette-1-base u-image u-image-circle u-image-3" alt=""
                                data-image-width="800" data-image-height="766"></div>
                            <h6 class="u-custom-font u-font-montserrat u-text u-text-8">Marry Hudson</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- <section class="u-clearfix u-grey-5 u-section-8" id="carousel_0fac">
        <div class="u-clearfix u-sheet u-valign-middle u-sheet-1">
            <div class="u-palette-1-dark-1 u-radius-20 u-shape u-shape-round u-shape-1"></div>
            <img src="/assets/images/nicepage/sdag-min.jpg" alt="" class="u-image u-image-round u-radius-20 u-image-1">
            <div class="u-container-style u-group u-radius-20 u-shape-round u-white u-group-1">
                <div class="u-container-layout u-container-layout-1">
                    <h2 class="u-text u-text-1">Effective Teams </h2>
                    <p class="u-text u-text-2">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
                        qui officia deserunt mollit anim id est laborum.</p>
                    <a href="https://nicepage.com/html-website-builder"
                        class="u-active-black u-border-none u-btn u-btn-round u-button-style u-hover-black u-palette-1-base u-radius-50 u-btn-2">learn
                        more</a>
                </div>
            </div>
        </div>
    </section> -->
    <section class="u-clearfix u-palette-1-base u-section-9" id="carousel_f03c">
        <!-- <div class="u-expanded-width u-grey-5 u-shape u-shape-rectangle u-shape-1"></div>
        <div class="u-list u-list-1">
            <div class="u-repeater u-repeater-1">
                <div
                    class="u-align-center u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-1">
                    <div class="u-container-layout u-similar-container u-valign-top u-container-layout-1"><span
                            class="u-icon u-icon-circle u-text-palette-1-base u-icon-1"><svg class="u-svg-link"
                                preserveAspectRatio="xMidYMin slice" viewBox="0 0 52 52" style="">
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-077e"></use>
                            </svg><svg class="u-svg-content" viewBox="0 0 52 52" x="0px" y="0px" id="svg-077e"
                                style="enable-background:new 0 0 52 52;">
                                <path style="fill:currentColor;" d="M38.853,5.324L38.853,5.324c-7.098-7.098-18.607-7.098-25.706,0h0
	C6.751,11.72,6.031,23.763,11.459,31L26,52l14.541-21C45.969,23.763,45.249,11.72,38.853,5.324z M26.177,24c-3.314,0-6-2.686-6-6
	s2.686-6,6-6s6,2.686,6,6S29.491,24,26.177,24z"></path>
                            </svg></span>
                        <h4 class="u-text u-text-1">our main office</h4>
                        <p class="u-text u-text-2">Al Qarhood, Dubai, UAE</p>
                    </div>
                </div>
                <div
                    class="u-align-center u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-2">
                    <div class="u-container-layout u-similar-container u-valign-top u-container-layout-2"><span
                            class="u-icon u-icon-circle u-text-palette-1-base u-icon-2"><svg class="u-svg-link"
                                preserveAspectRatio="xMidYMin slice" viewBox="0 0 513.64 513.64" style="">
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-9786"></use>
                            </svg><svg class="u-svg-content" viewBox="0 0 513.64 513.64" x="0px" y="0px" id="svg-9786"
                                style="enable-background:new 0 0 513.64 513.64;">
                                <g>
                                    <g>
                                        <path
                                            d="M499.66,376.96l-71.68-71.68c-25.6-25.6-69.12-15.359-79.36,17.92c-7.68,23.041-33.28,35.841-56.32,30.72    c-51.2-12.8-120.32-79.36-133.12-133.12c-7.68-23.041,7.68-48.641,30.72-56.32c33.28-10.24,43.52-53.76,17.92-79.36l-71.68-71.68    c-20.48-17.92-51.2-17.92-69.12,0l-48.64,48.64c-48.64,51.2,5.12,186.88,125.44,307.2c120.32,120.32,256,176.641,307.2,125.44    l48.64-48.64C517.581,425.6,517.581,394.88,499.66,376.96z">
                                        </path>
                                    </g>
                                </g>
                            </svg></span>
                        <h4 class="u-text u-text-3">phone number</h4>
                        <p class="u-text u-text-4">234-9876-5400 <br>888-0123-4567 (Toll Free)
                        </p>
                    </div>
                </div>
                <div
                    class="u-align-center u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-3">
                    <div class="u-container-layout u-similar-container u-valign-top u-container-layout-3"><span
                            class="u-icon u-icon-circle u-text-palette-1-base u-icon-3"><svg class="u-svg-link"
                                preserveAspectRatio="xMidYMin slice" viewBox="0 0 512 512" style="">
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-d01e"></use>
                            </svg><svg class="u-svg-content" viewBox="0 0 512 512" id="svg-d01e">
                                <path
                                    d="m201 12.714v184.286h267v-184.286c0-7.022-5.692-12.714-12.714-12.714h-241.572c-7.022 0-12.714 5.692-12.714 12.714zm63.89 33.131h70.271c8.284 0 15 6.716 15 15s-6.716 15-15 15h-70.271c-8.284 0-15-6.716-15-15s6.715-15 15-15zm0 75.142h139.22c8.284 0 15 6.716 15 15s-6.716 15-15 15h-139.22c-8.284 0-15-6.716-15-15s6.715-15 15-15z">
                                </path>
                                <path
                                    d="m472 227h-275c-22.091 0-40 17.909-40 40v205c0 22.091 17.909 40 40 40h275c22.091 0 40-17.909 40-40v-205c0-22.091-17.909-40-40-40zm-207.5 217.5h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-60h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-60h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm80 120h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-60h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-60h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm80 120h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-60h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-60h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15s-6.716 15-15 15z">
                                </path>
                                <path
                                    d="m87 227h-47c-22.091 0-40 17.909-40 40v205c0 22.091 17.909 40 40 40h47c22.091 0 40-17.909 40-40v-205c0-22.091-17.909-40-40-40z">
                                </path>
                            </svg></span>
                        <h4 class="u-text u-text-5">Fax</h4>
                        <p class="u-text u-text-6">1-234-567-8900</p>
                    </div>
                </div>
                <div
                    class="u-align-center u-container-style u-list-item u-radius-20 u-repeater-item u-shape-round u-white u-list-item-4">
                    <div class="u-container-layout u-similar-container u-valign-top u-container-layout-4"><span
                            class="u-icon u-icon-circle u-text-palette-1-base u-icon-4"><svg class="u-svg-link"
                                preserveAspectRatio="xMidYMin slice" viewBox="0 0 512 512" style="">
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-9f82"></use>
                            </svg><svg class="u-svg-content" viewBox="0 0 512 512" x="0px" y="0px" id="svg-9f82"
                                style="enable-background:new 0 0 512 512;">
                                <g>
                                    <g>
                                        <path
                                            d="M507.49,101.721L352.211,256L507.49,410.279c2.807-5.867,4.51-12.353,4.51-19.279V121    C512,114.073,510.297,107.588,507.49,101.721z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M467,76H45c-6.927,0-13.412,1.703-19.279,4.51l198.463,197.463c17.548,17.548,46.084,17.548,63.632,0L486.279,80.51    C480.412,77.703,473.927,76,467,76z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M4.51,101.721C1.703,107.588,0,114.073,0,121v270c0,6.927,1.703,13.413,4.51,19.279L159.789,256L4.51,101.721z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path
                                            d="M331,277.211l-21.973,21.973c-29.239,29.239-76.816,29.239-106.055,0L181,277.211L25.721,431.49    C31.588,434.297,38.073,436,45,436h422c6.927,0,13.412-1.703,19.279-4.51L331,277.211z">
                                        </path>
                                    </g>
                                </g>
                            </svg></span>
                        <h4 class="u-text u-text-7">Email</h4>
                        <p class="u-text u-text-8">
                            <a href="mailto:hello@theme.com"
                                class="u-active-none u-border-1 u-border-hover-palette-1-base u-border-no-left u-border-no-right u-border-no-top u-border-palette-1-base u-bottom-left-radius-0 u-bottom-right-radius-0 u-btn u-button-link u-button-style u-hover-none u-none u-radius-0 u-text-palette-1-base u-top-left-radius-0 u-top-right-radius-0 u-btn-1">hello@theme.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="u-clearfix u-layout-wrap u-layout-wrap-1">
            <div class="u-layout">
                <div class="u-layout-row">
                    <div class="u-align-left u-container-style u-layout-cell u-left-cell u-size-30 u-layout-cell-1">
                        <div class="u-container-layout u-container-layout-5">
                            <h2 class="u-text u-text-default u-text-9">Get in touch</h2>
                            <h6 class="u-custom-font u-text u-text-font u-text-10">We can ensure reliability, low cost
                                fares and most important, with safety and comfort in mind.</h6>
                            <div class="u-social-icons u-spacing-20 u-social-icons-1" id="social">
                                <a class="u-social-url" target="_blank" href="" aria-label="navigate to our facebook page"><span
                                        class="u-icon u-icon-circle u-social-facebook u-social-icon u-icon-5">
                                        <svg class="u-svg-link" preserveAspectRatio="xMidYMin slice"
                                            viewBox="0 0 112 112" style="">
                                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-f107">
                                            </use>
                                        </svg>
                                        <svg x="0px" y="0px" viewBox="0 0 112 112" id="svg-f107" class="u-svg-content">
                                            <path
                                                d="M75.5,28.8H65.4c-1.5,0-4,0.9-4,4.3v9.4h13.9l-1.5,15.8H61.4v45.1H42.8V58.3h-8.8V42.4h8.8V32.2 c0-7.4,3.4-18.8,18.8-18.8h13.8v15.4H75.5z">
                                            </path>
                                        </svg>
                                    </span>
                                </a>
                                <a class="u-social-url" target="_blank" href="" aria-label="navigate to our twitter page"><span
                                        class="u-icon u-icon-circle u-social-icon u-social-twitter u-icon-6">
                                        <svg class="u-svg-link" preserveAspectRatio="xMidYMin slice"
                                            viewBox="0 0 112 112" style="">
                                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-e140">
                                            </use>
                                        </svg>
                                        <svg x="0px" y="0px" viewBox="0 0 112 112" id="svg-e140" class="u-svg-content">
                                            <path
                                                d="M92.2,38.2c0,0.8,0,1.6,0,2.3c0,24.3-18.6,52.4-52.6,52.4c-10.6,0.1-20.2-2.9-28.5-8.2 c1.4,0.2,2.9,0.2,4.4,0.2c8.7,0,16.7-2.9,23-7.9c-8.1-0.2-14.9-5.5-17.3-12.8c1.1,0.2,2.4,0.2,3.4,0.2c1.6,0,3.3-0.2,4.8-0.7 c-8.4-1.6-14.9-9.2-14.9-18c0-0.2,0-0.2,0-0.2c2.5,1.4,5.4,2.2,8.4,2.3c-5-3.3-8.3-8.9-8.3-15.4c0-3.4,1-6.5,2.5-9.2 c9.1,11.1,22.7,18.5,38,19.2c-0.2-1.4-0.4-2.8-0.4-4.3c0.1-10,8.3-18.2,18.5-18.2c5.4,0,10.1,2.2,13.5,5.7c4.3-0.8,8.1-2.3,11.7-4.5 c-1.4,4.3-4.3,7.9-8.1,10.1c3.7-0.4,7.3-1.4,10.6-2.9C98.9,32.3,95.7,35.5,92.2,38.2z">
                                            </path>
                                        </svg>
                                    </span>
                                </a>
                                <a class="u-social-url" target="_blank" href="" aria-label="navigate to our instagram page"><span
                                        class="u-icon u-icon-circle u-social-icon u-social-instagram u-icon-7">
                                        <svg class="u-svg-link" preserveAspectRatio="xMidYMin slice"
                                            viewBox="0 0 112 112" style="">
                                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-4718">
                                            </use>
                                        </svg>
                                        <svg x="0px" y="0px" viewBox="0 0 112 112" id="svg-4718" class="u-svg-content">
                                            <path
                                                d="M55.9,32.9c-12.8,0-23.2,10.4-23.2,23.2s10.4,23.2,23.2,23.2s23.2-10.4,23.2-23.2S68.7,32.9,55.9,32.9z M55.9,69.4c-7.4,0-13.3-6-13.3-13.3c-0.1-7.4,6-13.3,13.3-13.3s13.3,6,13.3,13.3C69.3,63.5,63.3,69.4,55.9,69.4z">
                                            </path>
                                            <path
                                                d="M79.7,26.8c-3,0-5.4,2.5-5.4,5.4s2.5,5.4,5.4,5.4c3,0,5.4-2.5,5.4-5.4S82.7,26.8,79.7,26.8z">
                                            </path>
                                            <path
                                                d="M78.2,11H33.5C21,11,10.8,21.3,10.8,33.7v44.7c0,12.6,10.2,22.8,22.7,22.8h44.7c12.6,0,22.7-10.2,22.7-22.7 V33.7C100.8,21.1,90.6,11,78.2,11z M91,78.4c0,7.1-5.8,12.8-12.8,12.8H33.5c-7.1,0-12.8-5.8-12.8-12.8V33.7 c0-7.1,5.8-12.8,12.8-12.8h44.7c7.1,0,12.8,5.8,12.8,12.8V78.4z">
                                            </path>
                                        </svg>
                                    </span>
                                </a>
                                <a class="u-social-url" target="_blank" href="#" aria-label="navigate to our linkedin page"><span
                                        class="u-icon u-icon-circle u-social-icon u-social-linkedin u-icon-8">
                                        <svg class="u-svg-link" preserveAspectRatio="xMidYMin slice"
                                            viewBox="0 0 112 112" style="">
                                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-438b">
                                            </use>
                                        </svg>
                                        <svg x="0px" y="0px" viewBox="0 0 112 112" id="svg-438b" class="u-svg-content">
                                            <path
                                                d="M33.8,96.8H14.5v-58h19.3V96.8z M24.1,30.9L24.1,30.9c-6.6,0-10.8-4.5-10.8-10.1c0-5.8,4.3-10.1,10.9-10.1 S34.9,15,35.1,20.8C35.1,26.4,30.8,30.9,24.1,30.9z M103.3,96.8H84.1v-31c0-7.8-2.7-13.1-9.8-13.1c-5.3,0-8.5,3.6-9.9,7.1 c-0.6,1.3-0.6,3-0.6,4.8V97H44.5c0,0,0.3-52.6,0-58h19.3v8.2c2.6-3.9,7.2-9.6,17.4-9.6c12.7,0,22.2,8.4,22.2,26.1V96.8z">
                                            </path>
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="u-container-style u-layout-cell u-right-cell u-size-30 u-layout-cell-2">
                        <div class="u-container-layout u-valign-top u-container-layout-6">
                            <div class="u-form u-form-1">
                                <form id="contactMeForm" [formGroup]="contactMeForm" (ngSubmit)="contactMe()">
                                    <div *ngIf="error" class="alert alert-danger" role="alert">
                                        {{ error }}
                                    </div>
                                    <div class="mb-3 row">
                                        <div class="col-12">
                                            <input class="form-control text-dark" type="text" placeholder="Name or Company Name" id="name" aria-label="enter your name of company name"
                                                formControlName="name" [class]="submitted && contactMeForm.get('name').errors?'is-invalid':''">
                                            <div *ngIf="submitted && contactMeForm.get('name').errors" class="invalid-feedback">
                                                <span *ngIf="contactMeForm.get('name').errors.required">This value is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3 row">
                                        <div class="col-12">
                                            <input class="form-control text-dark" type="text" placeholder="Email Address" id="email"
                                                formControlName="email" aria-label="enter email address"
                                                [class]="submitted && contactMeForm.get('email').errors?'is-invalid':''">
                                            <div *ngIf="submitted && contactMeForm.get('email').errors" class="invalid-feedback">
                                                <span *ngIf="contactMeForm.get('email').errors.required">This value is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3 row">
                                        <div class="col-12">
                                            <textarea cols='60' rows='8' class="form-control text-dark" id="message" formControlName="message" aria-label="enter your message to solo technologies"
                                                [class]="submitted && contactMeForm.get('message').errors?'is-invalid':''">
                                            </textarea>
                                            <div *ngIf="submitted && contactMeForm.get('message').errors" class="invalid-feedback">
                                                <span *ngIf="contactMeForm.get('message').errors.required">This value is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="button-items d-flex align-items-center justify-content-end mb-2">
                                    <button class="u-active-white u-border-2 u-border-white u-btn u-btn-round u-button-style u-hover-white u-none u-radius-50 u-btn-2" type="submit" form="contactMeForm">
                                        <!-- <i *ngIf="this.loading" class="bx bx-loader bx-spin font-size-18 align-middle me-2"></i> -->
                                        <span>Send</span>
                                    </button>
                                </div>
                                <!-- <form id="contactMeForm" [formGroup]="contactMeForm" (ngSubmit)="contactMe()"
                                    class="u-clearfix u-form-spacing-30 u-form-vertical u-inner-form"
                                    style="padding: 10px">
                                    <div *ngIf="error" class="alert alert-danger" role="alert">
                                        {{ error }}
                                    </div>
                                    <div class="u-form-email u-form-group u-form-partition-factor-2">
                                        <label for="email-319a"
                                            class="u-label u-text-body-alt-color u-label-1">Email</label>
                                        <input type="email" placeholder="Enter a valid email address" id="email-319a"
                                            name="email"
                                            class="u-border-2 u-border-no-left u-border-no-right u-border-no-top u-border-white u-input u-input-rectangle"
                                            required="">
                                    </div>
                                    <div class="u-form-group u-form-name u-form-partition-factor-2">
                                        <label for="name-319a"
                                            class="u-label u-text-body-alt-color u-label-2">Name</label>
                                        <input type="text" placeholder="Enter your Name" id="name" name="name"
                                            class="form-control u-border-2 u-border-no-left u-border-no-right u-border-no-top u-border-white u-input u-input-rectangle"
                                            formControlName="name" [class]="submitted && contactMeForm.get('name').errors?'is-invalid':''">
                                            <div *ngIf="submitted && contactMeForm.get('name').errors" class="invalid-feedback">
                                                <span *ngIf="contactMeForm.get('name').errors.required">This value is required.</span>
                                            </div>
                                    </div>
                                    <div class="u-form-group u-form-message">
                                        <label for="message-319a"
                                            class="u-label u-text-body-alt-color u-label-3">Message</label>
                                        <textarea placeholder="Enter your message" rows="4" cols="50" id="message-319a"
                                            name="message"
                                            class="u-border-2 u-border-no-left u-border-no-right u-border-no-top u-border-white u-input u-input-rectangle"
                                            required=""></textarea>
                                    </div>
                                    <div class="u-align-left u-form-group u-form-submit">
                                        <a href="#" class="u-btn u-btn-submit u-button-style u-white u-btn-2">Submit</a>
                                        <input type="submit" value="submit" class="u-form-control-hidden">
                                    </div>
                                    <div class="u-form-send-message u-form-send-success"> Thank you! Your message has
                                        been sent. </div>
                                    <div class="u-form-send-error u-form-send-message"> Unable to send your message.
                                        Please fix errors then try again. </div>
                                    <input type="hidden" value="" name="recaptchaResponse">
                                </form> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <footer class="u-backlink u-clearfix u-grey-80">
        <p class="u-text">
            <span>Copyright © 2022 Solo Technologies</span>
        </p>
    </footer>

</div>

<ng-template #needCode role="document" let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">We Will Pick You Up !</h5>
        <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form id="contactMeForm" [formGroup]="contactMeForm" (ngSubmit)="contactMe()">
            <div *ngIf="error" class="alert alert-danger" role="alert">
                {{ error }}
            </div>
            <div class="mb-3 row">
                <div class="col-12">
                    <input class="form-control" type="text" placeholder="Phone number (+971 - XXX XXXX XXX)" id="name" aria-label="enter phone number"
                        formControlName="name" [class]="submitted && contactMeForm.get('name').errors?'is-invalid':''">
                    <div *ngIf="submitted && contactMeForm.get('name').errors" class="invalid-feedback">
                        <span *ngIf="contactMeForm.get('name').errors.required">This value is required.</span>
                    </div>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-12">
                    <input class="form-control" type="text" placeholder="Email Address" id="email" aria-label="enter email address"
                        formControlName="email"
                        [class]="submitted && contactMeForm.get('email').errors?'is-invalid':''">
                    <div *ngIf="submitted && contactMeForm.get('email').errors" class="invalid-feedback">
                        <span *ngIf="contactMeForm.get('email').errors.required">This value is required.</span>
                    </div>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-12">
                    <textarea cols='60' rows='8' class="form-control" id="message" formControlName="message" aria-label="enter your message"
                        [class]="submitted && contactMeForm.get('message').errors?'is-invalid':''">
                    </textarea>
                    <div *ngIf="submitted && contactMeForm.get('message').errors" class="invalid-feedback">
                        <span *ngIf="contactMeForm.get('message').errors.required">This value is required.</span>
                    </div>
                </div>
            </div>
        </form>
        <div class="button-items d-flex align-items-center justify-content-end mb-2">
            <button class="btn btn-primary mb-0" type="submit" form="contactMeForm" [disabled]="this.success">
                <i *ngIf="this.loading" class="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
                <span *ngIf="!this.success">Send</span>
                <span *ngIf="this.success">Sent</span>
            </button>
        </div>
    </div>
</ng-template>