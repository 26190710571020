import { Component , OnInit} from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  constructor(private meta: Meta,private title: Title) {
    this.meta.addTags([
      {charset:"utf-8"},
      {name:"robots", content:"max-image-preview:large"},
      {name:"viewport", content:"width=device-width, initial-scale=1"},
      {name: 'description', content: 'Result-driven web design company in Dubai. Specialized in website design, web development, web application development, e-commerce websites, SEO &amp; digital marketing.'},
      {name: 'keywords', content: 'HTML, CSS, JavaScript, web design, web development, mobile application development, web application development, e-commerce, ERP, EMR, SAAS, Electronic medical records, seha, nabidh, clinic software, software, store software, uae, dubai, united arab emirates, tech company, development company, android development, ios development, magento, online store, online payment'},
      {property:"og:locale", content:"en_US"},
      {property:"og:site_name", content:"Solo | Web Design Company Dubai"},
      {property:"og:type", content:"activity"},
      {property:"og:title", content:"Web Design Company Dubai | We Design Result Driven Websites in UAE | #1 Digital Agency"},
      {property:"og:description", content:"Result driven website design company &amp; digital marketing agency in Dubai. Specialized in website design, web application development ,e-commerce websites ,seo &amp; digital marketing."},
      {property:"og:url", content:"https://solo-technologies.com/"},
      {property:"og:image", content:"https://solo-technologies.com/assets/images/nicepage/SOLODEVLOGO.png"},
      {property:"og:image:secure_url", content:"https://solo-technologies.com/assets/images/nicepage/SOLODEVLOGO.png"},
      {property:"og:image:width", content:"300"},
      {property:"og:image:height", content:"300"},
      {name:"twitter:card" ,content:"summary"},
      {name:"twitter:title" ,content:"Web Design Company Dubai | We Design Result Driven Websites in UAE | #1 Digital Agency"},
      {name:"twitter:description" ,content:"Result driven website design company &amp; digital marketing agency in Dubai. Specialized in website design, web application development ,e-commerce websites ,seo &amp; digital marketing."},
      {name:"twitter:image" ,content:"https://solo-technologies.com/assets/images/nicepage/SOLODEVLOGO.png"},
      {name:"geo.region" ,content:"AE" },
      {name:"geo.placename" ,content:"Dubai" },
      {name:"geo.position" ,content:"25.074282;55.188539" },
      {name:"ICBM" ,content:"25.074282, 55.188539" },
    ]);
    this.setTitle('Solo - Every Pixel Matters');
  }

  public setTitle( newTitle: string) {
    this.title.setTitle( newTitle );
  }
  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
}
